import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

// Referenced from below
// https://www.gatsbyjs.com/tutorial/seo-and-social-sharing-cards-tutorial/#reach-skip-nav
// TODO: JSONLD https://nystudio107.com/blog/json-ld-structured-data-and-erotica
function Seo({ description, image, title, keywords, pathname })
{
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          keywords
          siteUrl
        }
      }
    }
  `)

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaImage = {
    src: `${site.siteMetadata.siteUrl}${image.src}`,
    width: image.width,
    height: image.height,
  }
  const metaKeywords = (keywords || []).concat(site.siteMetadata.keywords).join(",")
  const metaAuthor = site.siteMetadata.author || ''
  const metaCanonical = [
    {
      rel: "canonical",
      href: `${site.siteMetadata.siteUrl}${pathname}`,
    }
  ]

  const metaMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: "keywords",
      content: metaKeywords,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: metaAuthor,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      property: "og:image",
      content: metaImage.src,
    },
    {
      property: "og:image:width",
      content: metaImage.width,
    },
    {
      property: "og:image:height",
      content: metaImage.height,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    }
  ]

  return (
    <Helmet
      htmlAttributes={{ "lang": "en" }}
      meta={metaMeta}
      title={metaTitle}
      link={metaCanonical}
    >
      <script src="https://cdn.usefathom.com/script.js" data-site="BYLXWEYE" defer></script>
    </Helmet>
  )
}

Seo.defaultProps = {
  title: '',
  description: '',
  keywords: [],
  image: {
    src: '/og/default.jpg',
    width: 1200,
    height: 630,
  },
  pathname: '/',
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  pathname: PropTypes.string,
}

export default Seo