import PropTypes from "prop-types"
import React from "react"

function Layout({ children })
{
  return (
    <main className="font-theme text-dark-blue tracking-wide w-full h-full min-h-screen bg-light-gray overflow-x-hidden px-4">
      <div className="max-w-screen-sm mx-auto text-center">
        {children}
      </div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout